<template>
  <div class="home">
    <v-layout row wrap>
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
            <router-link to="/">Dashboard</router-link> / <router-link to="/master-listings">Master Listing</router-link> / {{ helicopter.code }}
        </h1>
      </v-flex>
      <v-flex xs12 md12 lg8>
        <v-simple-table>
          <tbody>
            <tr>
              <th class="fs-12 primary--text">AC_unique_code</th>
              <th class="fs-12">{{ helicopter.code }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Engine</th>
              <th class="fs-12">{{ helicopter.engine }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Year of reg.</th>
              <th class="fs-12">{{ helicopter.year_of_registration }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Date UAE reg.</th>
              <th class="fs-12">{{ helicopter.date_uae_registration }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Lease Type</th>
              <th class="fs-12">{{ helicopter.lease_type }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Lease Currency</th>
              <th class="fs-12">{{ helicopter.lease_currency }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Total Lease Value</th>
              <th class="fs-12">{{ helicopter.total_lease_value }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Monthly Lease Due Date</th>
              <th class="fs-12">{{ helicopter.monthly_lease_due_date }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Rate/hr</th>
              <th class="fs-12">{{ helicopter.rate_per_hour }}</th>
            </tr>
        
            <tr>
              <th class="fs-12 primary--text">Options</th>
              <th class="fs-12">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" class="black--text" light v-bind="attrs" v-on="on">
                      Manage Helicopter <span><v-icon>mdi-menu-down</v-icon></span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>Upload Schedule</v-list-item-title>
                    </v-list-item>
    
                    <v-list-item>
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
    
                    <v-list-item>
                      <v-list-item-title>Delete Account</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </th>
            </tr>
          </tbody>
        </v-simple-table>
      </v-flex>
    </v-layout>
  </div>
</template>
    
    <script>
import { BASE_URL } from "@/config";
import Axios from "axios";

export default {
  data() {
    return {
      helicopter: [],
    };
  },
  mounted() {
    this.fetchHelicopter();
  },
  methods: {
    async fetchHelicopter() {
      let url = BASE_URL + "/helicopter/" + this.$route.params.id;
      let { data } = await Axios.get(url);
      this.helicopter = data;
    },
    edit() {
      this.$router.push({
        name: "EditHelicopter",
        params: { id: this.$route.params.id },
      });
    },
  },
};
</script>

<style scoped>
    .fs-12{
        font-size: 12pt !important;
    }
</style>

